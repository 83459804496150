import React from "react";
import PropTypes from "prop-types";
import { Feed } from "semantic-ui-react";

const Review = ({ name, date, review, image = "circlepaw.png" }) => (
  <Feed.Event>
    <Feed.Label image={image} />
    <Feed.Content>
      <Feed.User>{`${name} `}</Feed.User>
      <Feed.Meta>{date}</Feed.Meta>
      <Feed.Extra text>{review}</Feed.Extra>
    </Feed.Content>
    <Feed.Content extra></Feed.Content>
  </Feed.Event>
);

Review.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  date: PropTypes.string,
  review: PropTypes.string
};
export default Review;
