import React, { useState } from "react";
import PropTypes from "prop-types";
import { Header, Grid, Modal, Image, Button, Reveal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { services } from "../content.json";

const Services = ({ floated, serviceKey }) => {
  const { imageSrc, service, modalContent, modalImageSize } = services[
    serviceKey
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const image = (
    <Image
      onClick={() => setModalOpen(true)}
      circular
      bordered
      size="massive"
      src={imageSrc}
    />
  );
  const reveal = (
    <Reveal animated="move up">
      <Reveal.Content visible>{image}</Reveal.Content>
      <Reveal.Content hidden textAlign="center">
        <Link>
          <Header
            as="h2"
            color="pawblue"
            onClick={() => setModalOpen(true)}
            style={{ marginTop: "5em" }}
          >
            Click for {service} info!
          </Header>
        </Link>
      </Reveal.Content>
    </Reveal>
  );
  return (
    <Grid.Column
      floated={floated}
      width={5}
      textAlign="center"
      key={`${service}+grid+column`}
    >
      <Modal
        trigger={reveal}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        size="small"
      >
        <Modal.Header>{service}</Modal.Header>
        <Modal.Content image>
          <Image wrapped src={imageSrc} size={modalImageSize} />
          <Modal.Description>
            <Header>{modalContent}</Header>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <Button
        size="huge"
        color="pawblue"
        style={{ marginTop: "2em" }}
        onClick={() => setModalOpen(true)}
      >
        {service}
      </Button>
    </Grid.Column>
  );
};
export default Services;

Services.propTypes = {
  floated: PropTypes.string,
  service: PropTypes.string,
  imageSrc: PropTypes.string,
  serviceKey: PropTypes.string
};
