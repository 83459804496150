import React from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import { homepage } from "../content.json";

const HomePage = () => (
  //   const image = <Image src="pawlogo.png" size="small" />;
  <Grid container stackable verticalAlign="middle">
    <Grid.Row>
      <Grid.Column width={3}>
        <Header as="h3" style={{ fontSize: "2em" }}>
          Hi, I&apos;m Jayme!
        </Header>
      </Grid.Column>
      <Grid.Column width={2}>
        <Image src="squarecheeks.jpeg" circular size="tiny" />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={10}>
        {homepage.paragraphs.map(paragraph => (
          <p style={{ fontSize: "1.33em" }} key={paragraph}>
            {paragraph}
          </p>
        ))}
      </Grid.Column>
      <Grid.Column floated="right" width={6}>
        <Image rounded size="large" src="millie.jpeg" />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
export default HomePage;
