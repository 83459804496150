/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Grid } from "semantic-ui-react";
import { contactme } from "../content.json";
import ContactMeForm from "./contactmeform";
import ContactMeButton from "./contactmebutton";

const ContactMe = () => (
  <Grid key="contactme" container stackable verticalAlign="middle">
    <Grid.Row>
      <Grid.Column width={8} textAlign="center">
        <Grid key="socialmedia" stretched textAlign="center" stackable>
          <Grid.Row>{contactme.socialMedia.map(ContactMeButton)}</Grid.Row>
          <Grid.Row>{contactme.personal.map(ContactMeButton)}</Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column floated="right" width={6}>
        <ContactMeForm />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default ContactMe;
