import React from "react";
import { Grid, Header, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Service from "./service";

const Services = () => (
  <Grid
    container
    stackable
    verticalAlign="middle"
    // style={{ marginTop: "10em" }}
  >
    <Grid.Row>
      <Service
        floated="left"
        imageSrc="pawlogo.png"
        textAlign="center"
        service="Drop-By Visits"
        serviceKey="dropInVisits"
      />
      <Service
        floated="center"
        imageSrc="dolly.jpg"
        service="Dog Walking"
        serviceKey="dogWalking"
      />
      <Service
        floated="right"
        imageSrc="happydog.jpeg"
        service="House Sitting"
        serviceKey="petSitting"
      />
    </Grid.Row>
    <Grid.Row>
      <Grid.Column textAlign="center">
        <Link to="/contactme">
          <Button size="huge" color="pawblue" style={{ marginTop: "5em" }}>
            Book Now!
          </Button>
        </Link>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
export default Services;
