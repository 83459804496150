/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid, Dropdown } from "semantic-ui-react";
import uuid from "uuid";
import { getCalendar } from "../clients/rover";

const months = moment
  .months()
  .map(month => ({ key: month, text: month, value: month }));
const getYears = year => {
  const numYear = Number(year);
  const arr = [numYear];
  let front = numYear;
  let back = numYear;
  for (let i = 0; i < 10; i += 1) {
    arr.unshift((back -= 1));
    arr.push((front += 1));
  }
  console.log(arr);
  return arr;
};
const years = getYears(moment().format("Y")).map(year => ({
  key: year,
  text: year,
  value: year
}));
const numRows = 5;
const numCols = 7;

const generateCells = ({ dateObject, calendarData = [] }) => {
  const firstDayOfMonth = () =>
    moment(dateObject)
      .startOf("month")
      .format("d");
  const isCurrentDay = day =>
    day === moment().date() &&
    moment().month() === dateObject.month() &&
    moment().year() === dateObject.year();
  const cells = [];
  for (let i = 0; i < firstDayOfMonth(); i += 1) {
    cells.push({ day: "" });
  }
  for (let i = 1; i <= dateObject.daysInMonth(); i += 1) {
    const isAvailable = calendarData.find(
      ({ day, available }) => day === i && available
    );
    cells.push({ day: i, current: isCurrentDay(i), isAvailable });
  }

  for (let i = cells.length; i < numRows * numCols; i += 1) {
    cells.push({ day: "" });
  }
  return cells;
};

const generateEmptyCalendar = () => {
  const cal = [];
  for (let i = 0; i < numRows; i += 1) {
    cal.push([]);
    for (let j = 0; j < numCols; j += 1) {
      cal[i].push({ day: -1 });
    }
  }
  return cal;
};

const populateCalendar = ({ cal, cells }) => {
  let cell = 0;
  for (let i = 0; i < numRows; i += 1) {
    for (let j = 0; j < numCols; j += 1) {
      cal[i][j] = cells[cell];
      cell += 1;
    }
  }
};

const generateCalendar = ({ dateObject, calendarData }) => {
  const cells = generateCells({ dateObject, calendarData });
  const cal = generateEmptyCalendar();
  populateCalendar({ cal, cells });
  return cal;
};

const getMonth = ({ dateObject }) => dateObject.format("MMMM");
const getDate = ({ dateObject }) => dateObject.format("MMM Do YY");

const Almanac = () => {
  console.log(years);
  const [dateObject, setDateObject] = useState(moment());
  const [month, setMonth] = useState(getMonth({ dateObject }));
  const [year, setYear] = useState(dateObject.year());
  const [cal, setCal] = useState(generateCalendar({ dateObject }));
  useEffect(() => {
    console.log(getDate({ dateObject }));
    const getRoverData = async () => {
      const calendarData = await getCalendar({
        month: dateObject.format("M"),
        year: dateObject.year(),
        endDate: dateObject.daysInMonth()
      });
      console.log(calendarData);
      setCal(generateCalendar({ dateObject, calendarData }));
    };
    getRoverData();
  }, [dateObject]);
  return (
    <Grid
      centered
      celled="internally"
      style={{ marginTop: "5em" }}
      verticalAlign
    >
      <Grid.Row>
        <Grid.Column width={7} color="pawblue" key={uuid()} textAlign="center">
          <Dropdown
            defaultValue={month}
            options={months}
            onChange={(event, { value: newMonth }) => {
              setMonth(newMonth);

              setDateObject(
                moment(dateObject)
                  .set("month", months.map(({ key }) => key).indexOf(newMonth))
                  .set("year", year)
              );
            }}
          />
        </Grid.Column>
        <Grid.Column width={7} color="pawblue" key={uuid()} textAlign="center">
          <Dropdown
            defaultValue={year}
            options={years}
            onChange={(event, { value: newYear }) => {
              setYear(newYear);
              setDateObject(
                moment(dateObject)
                  .set("month", months.map(({ key }) => key).indexOf(month))
                  .set("year", newYear)
              );
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {moment.weekdaysMin().map(day => (
          <Grid.Column textAlign="center" key={day} color="pawblue" width={2}>
            {day}
          </Grid.Column>
        ))}
      </Grid.Row>
      {cal.map(r => (
        <Grid.Row key={`row${uuid()}`}>
          {r.map(({ day, current = false, isAvailable = false }) => (
            <Grid.Column
              textAlign="center"
              color={isAvailable ? "pawgreen" : current ? "pawblue" : "white"}
              key={`col${day || uuid()}`}
              width={2}
            >
              {day}
            </Grid.Column>
          ))}
        </Grid.Row>
      ))}
    </Grid>
  );
};

export default Almanac;
