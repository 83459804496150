import { FETCH_IMAGES } from "./types";
import { getAllImages } from "../clients/rover";

export default function fetchImages() {
  return async dispatch => {
    const images = await getAllImages();
    dispatch({
      type: FETCH_IMAGES,
      payload: images
    });
  };
}
