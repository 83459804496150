/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Image, Icon, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import fetchImages from "../actions/fetchimages";

const Slider = ({ iconSize = "massive", fetchImages: getImages, images }) => {
  const [curr, setCurr] = useState(0);
  const goToNext = () => {
    setCurr(curr === images.length - 1 ? 0 : curr + 1);
  };

  const goToPrev = () => {
    setCurr(curr === 0 ? images.length - 1 : curr - 1);
  };
  useEffect(() => {
    getImages();
  }, []);

  // setInterval(goToNext, 15000);
  // useEffect(() => {
  //   console.log(images);
  //   if (images && images.length) {
  //     clearTimeout();
  //     setTimeout(goToNext, 15000);
  //   }
  // }, [curr]);
  const src = images && images[curr] ? images[curr] : "pawlogo.png";
  return (
    <Grid verticalAlign="middle" textAlign="center" stretched>
      <Grid.Row centered>
        <Grid.Column width={2}>
          <Icon
            fitted
            link
            onClick={() => {
              clearInterval();
              goToPrev();
            }}
            size={iconSize}
            name="angle left"
            color="pawblue"
          />
        </Grid.Column>
        <Grid.Column width={12} textAlign="center">
          <Image centered size="huge" key={`${images[curr]}image`} src={src} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Icon
            fitted
            link
            onClick={() => {
              clearInterval();
              goToNext();
            }}
            size={iconSize}
            name="angle right"
            color="pawblue"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Slider.propTypes = {
  iconSize: PropTypes.string,
  images: PropTypes.array,
  fetchImages: PropTypes.func
};

const mapStateToProps = ({ gallery: { images } }) => ({ images });
export default connect(
  mapStateToProps,
  { fetchImages }
)(Slider);
