/* eslint-disable no-alert */
import React, { useState } from "react";
import { Form, Button } from "semantic-ui-react";
import sendEmail from "../clients/sendEmail";

const ContactMeForm = () => {
  const [form, setForm] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handleFormChange = event => {
    console.log(event.target.value);
    setForm(event.target.value);
  };
  const handleNameChange = event => {
    setName(event.target.value);
  };
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleSubmit = async event => {
    try {
      await sendEmail({ form, email, name });
      console.log(form);
      alert(
        `an email was sent to jaymespetservices and will get back to you shortly!${event.form +
          event.email +
          event.name}`
      );
    } catch (e) {
      alert(JSON.stringify(e));
    }
  };

  return (
    <Form>
      <label style={{ color: "#5271ff" }}>Name</label>
      <Form.Field>
        <input
          placeholder="Full Name"
          onChange={handleNameChange}
          value={name}
        />
      </Form.Field>

      <label style={{ color: "#5271ff" }}>Email</label>
      <Form.Field>
        <input
          placeholder="name@example.com"
          onChange={handleEmailChange}
          value={email}
        />
      </Form.Field>
      <label style={{ color: "#5271ff" }}>About Your Pet!</label>

      <Form.TextArea
        placeholder="Tell me more about your pets..."
        onChange={handleFormChange}
        value={form}
      />
      <Button
        type="submit"
        color="pawblue"
        onClick={() => handleSubmit({ form, name, email })}
      >
        Submit
      </Button>
    </Form>
  );
};

export default ContactMeForm;
