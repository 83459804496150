import React, { useState, useEffect } from "react";
import { Feed } from "semantic-ui-react";
import { getReviews } from "../clients/rover";
import { reviews as defaultReviews } from "../content.json";

import Review from "./review";

const Reviews = () => {
  const [reviews, setReviewData] = useState(defaultReviews);
  useEffect(() => {
    const getAndSetImages = async () => {
      const reviewData = await getReviews();
      setReviewData(reviewData.concat(defaultReviews));
    };
    getAndSetImages();
  }, []);

  return (
    <Feed size="large">
      {reviews.map(({ review, name, date, image }) => (
        <Review
          key={`${review + name + date}`}
          name={name}
          date={date}
          review={review}
          image={image}
        />
      ))}
    </Feed>
  );
};

export default Reviews;
