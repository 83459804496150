import React from "react";
import { Icon, Reveal, Header, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

const NavBarItem = ({
  iconName,
  link,
  reveal,
  marginTop = "1.5em",
  animation = "small fade",
  iconSize = "huge",
  marginLeft = "1em",
  marginRight = "0em",
  fontSize = "1em"
}) => {
  const icon = (
    <Grid.Column floated="right" width={1} style={{ marginTop: "5em" }}>
      <Link to={link}>
        <Reveal animated={animation}>
          <Reveal.Content visible>
            <Icon
              size={iconSize}
              name={iconName}
              color="pawblue"
              style={{ background: "#ffd4d7" }}
            />
          </Reveal.Content>
          <Reveal.Content hidden>
            <Header
              as="h1"
              color="pawblue"
              style={{ marginTop, marginLeft, fontSize, marginRight }}
            >
              {reveal}
            </Header>
          </Reveal.Content>
        </Reveal>
      </Link>
    </Grid.Column>
  );
  return icon;
};

export default NavBarItem;
