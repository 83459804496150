import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Responsive } from "semantic-ui-react";
import { Provider } from "react-redux";
import store from "./store";
import {
  Sidebar,
  HomePage,
  Calendar,
  ContactMe,
  Gallery,
  NavBar,
  Reviews,
  Services
} from "./components";

const getWidth = () =>
  typeof window === "undefined"
    ? Responsive.onlyTablet.minWidth
    : window.innerWidth;
const App = () => {
  const pages = (
    <Switch>
      <Route path="/homepage" component={HomePage} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/calendar" component={Calendar} />
      <Route exact path="/contactme" component={ContactMe} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/reviews" component={Reviews} />
      <Route exact path="/" component={HomePage} />
    </Switch>
  );

  const Desktop = () => (
    <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
      <NavBar />
      {pages}
    </Responsive>
  );

  const Mobile = () => (
    <Responsive getWidth={getWidth} maxWidth={Responsive.onlyTablet.minWidth}>
      <Sidebar> {pages}</Sidebar>
    </Responsive>
  );

  return (
    <Provider store={store}>
      <Container>
        <Desktop />
        <Mobile />
      </Container>
    </Provider>
  );
};
export default App;
