import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Icon,
  Sidebar as SemanticSideBar,
  Menu,
  Responsive,
  Image,
  Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";

const Sidebar = ({ children }) => {
  const [sidebarOpened, setSideBarOpen] = useState(false);
  const SidebarItem = ({ name, link }) => (
    <Menu.Item>
      <Link to={link} onClick={() => setSideBarOpen(false)}>
        <Header as="h1" color="pawblue">
          {name}
        </Header>
      </Link>
    </Menu.Item>
  );
  SidebarItem.propTypes = {
    name: PropTypes.string,
    link: PropTypes.string
  };

  return (
    <Responsive>
      <SemanticSideBar
        as={Menu}
        vertical
        animation="push"
        inverted
        onHide={() => setSideBarOpen(false)}
        visible={sidebarOpened}
        style={{ background: "#ffd4d7" }}
      >
        <SidebarItem name="Home" link="/homepage" />
        <SidebarItem name="Services" link="/services" />
        <SidebarItem name="Calendar" link="/calendar" />
        <SidebarItem name="Contact Me!" link="/contactme" />
        <SidebarItem name="Gallery" link="/gallery" />
        <SidebarItem name="Reviews" link="/reviews" />
      </SemanticSideBar>

      <SemanticSideBar.Pusher dimmed={sidebarOpened}>
        <Icon
          link
          size="big"
          name="sidebar"
          color="pawblue"
          onClick={() => setSideBarOpen(true)}
        />
        <Image centered src="pawlogo.png" circular size="medium" />

        {children}
      </SemanticSideBar.Pusher>
    </Responsive>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  children: PropTypes.node
};
