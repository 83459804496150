import React from "react";
import { Responsive, Container } from "semantic-ui-react";
import Slider from "./slider";

const getWidth = () =>
  typeof window === "undefined"
    ? Responsive.onlyTablet.minWidth
    : window.innerWidth;
const Gallery = () => {
  const Desktop = () => (
    <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
      <Slider />
    </Responsive>
  );

  const Mobile = () => (
    <Responsive getWidth={getWidth} maxWidth={Responsive.onlyTablet.minWidth}>
      <Slider iconSize="huge" />
    </Responsive>
  );

  return (
    <Container>
      <Desktop />
      <Mobile />
    </Container>
  );
};
export default Gallery;
