import React from "react";
import { Grid, Image } from "semantic-ui-react";
import NavBarItem from "./navbaritem";

const NavBar = () => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={4}>
        <Image
          avatar
          src="pawlogocircle.png"
          size="small"
          // style={{ width: "960px", height: "auto" }}
        />
      </Grid.Column>
      <NavBarItem
        link="/homepage"
        iconName="home"
        position="right"
        reveal="Home"
      />

      <NavBarItem
        link="/services"
        iconName="paw"
        position="left"
        reveal="Services"
        marginLeft="0.5em"
      />
      <NavBarItem
        link="/calendar"
        iconName="calendar alternate outline"
        position="left"
        reveal="Calendar"
        marginLeft="0em"
      />
      <NavBarItem
        link="/contactme"
        iconName="call"
        position="left"
        reveal="Contact Me!"
        marginLeft="0em"
        marginTop="1em"
        marginRight="5em"
      />
      <NavBarItem
        link="/gallery"
        iconName="photo"
        position="left"
        reveal="Gallery"
        marginLeft="0.7em"
      />

      <NavBarItem
        link="/reviews"
        iconName="pencil"
        position="left"
        reveal="Reviews"
        marginLeft="0.5em"
      />
    </Grid.Row>
  </Grid>
);

export default NavBar;
