/* eslint-disable camelcase */
import axios from "axios";

const corsProxy = "https://cors-anywhere.herokuapp.com/";
const rover = "http://rover.com/api/v4/people/NJz2wMVN";
const endpoint = `${corsProxy}${rover}`;

const getImages = async () => {
  const response = await axios.get(`${endpoint}/images/`);
  return response.data.results.map(({ medium }) => medium);
};

const getStayMediaImages = async () => {
  const response = await axios.get(`${endpoint}/stay-media/`);
  return response.data.results.map(({ object: { medium } }) => medium);
};

const _getReviews = async () => {
  const response = await axios.get(`${endpoint}/reviews/`);
  console.log(response);
  return response.data;
};

const getData = async ({ next: customEndpoint }) => {
  const response = await axios.get(`${corsProxy}${customEndpoint}`);
  console.log(response);

  return response.data;
};

const getReviews = async () => {
  const reviews = [];
  let reviewData = await _getReviews();
  while (reviewData.next) {
    reviewData.results.map(
      ({
        description,
        added,
        poster: {
          short_name,
          default_image: { small }
        }
      }) =>
        reviews.push({
          review: description,
          date: new Date(added).toLocaleDateString(),
          image: small,
          name: short_name
        })
    );

    reviewData = await getData(reviewData);
  }
  console.log(reviews);

  return reviews;
};

const getAllImages = async () => {
  const stayMediaImages = await getStayMediaImages();
  const images = await getImages();
  const allImages = images.concat(stayMediaImages);
  Promise.all(allImages.map(async src => axios.get(src)));
  return [...new Set(allImages)];
};

const getCalendar = async ({ month, year, endDate }) => {
  const response = await axios.get(
    `${endpoint}/public-calendar/?start_date=${year}-${month}-01&end_date=${year}-${month}-${endDate}`
  );
  console.log(response);
  return response.data.availability.map(({ date, calendars }) => ({
    day: Number(date.split("-")[2]),
    date,
    calendars,
    available: calendars.some(
      ({ spaces_available = 0 }) => spaces_available > 0
    )
  }));
};

export { getReviews, getAllImages, getCalendar };
