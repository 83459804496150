import React from "react";
import Almanac from "./almanac";

const Calendar = () => (
  <div>
    <Almanac />
  </div>
);

export default Calendar;
