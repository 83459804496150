import axios from "axios";

const endpoint = "https://ehng2vadpb.execute-api.us-east-1.amazonaws.com/PRD";

const sendEmail = async ({ form, name, email }) => {
  try {
    const response = await axios.post(endpoint, {
      form,
      name,
      email
    });
    return { message: "success!!", response };
  } catch (error) {
    console.log("ERROR THIS SHIT FAILED", error);
    throw error;
  }
};

export default sendEmail;
