/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Grid, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { contactme } from "../content.json";

const ContactMeButton = ({ icon, title, url }) => (
  <Grid.Column
    key={`gridcolumn${icon + title + url}`}
    width={8}
    textAlign="center"
  >
    <a
      href={url}
      key={`link${icon + title + url}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon
        color="pawblue"
        key={`icon${icon + title + url}`}
        link
        name={icon}
        aria-label={title}
        size="huge"
        fitted
      />
    </a>
    <Header color="grey" key={`header${icon + title + url}`}>
      {title}
    </Header>
  </Grid.Column>
);

ContactMeButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string
};

export default ContactMeButton;
